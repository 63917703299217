<template>
  <div>
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="addItem"
        @change="formChanged()"
      >
        <!-- Row Loop -->
        <b-row v-for="(item, index) in Items" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col md="2">
            <b-form-group label="Operation Step" label-for="Operation-stepoperationName">
              <b-form-select
                id="operationName"
                v-model="item.operationName"
                :options="Product.operationSteps"
                value-field="name"
                text-field="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Quality Name" label-for="name">
              <b-form-input
                id="name"
                v-model="item.name"
                type="text"
                placeholder="Quality name"
              />
            </b-form-group>
          </b-col>
          <!-- Cost -->
          <b-col md="2">
            <b-form-group label="Minimum Value" label-for="minVal">
              <b-form-input
                id="minVal"
                v-model="item.minValue"
                type="text"
                placeholder="Accepted Value"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label=" Maximum Value" label-for="maxVal">
              <b-form-input
                id="maxVal"
                v-model="item.maxValue"
                type="text"
                placeholder="Accepted Value"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col lg="2" md="3" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="addItem"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add Quality</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: this.$route.params.id,
      nextSerial: 1,
      deletedItems: [],
    };
  },
  props: {
    Product: {
      type: Object,
      required: true,
    },
    Items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.initTrHeight();
    this.refreshItems(this.Product);
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addItem() {
      let item = {
        serial: this.nextSerial,
        operationName: null,
        name: null,
        minValue: null,
        maxValue: null,
      };
      this.Items.push(item);
      this.nextSerial += 1;

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.deletedItems.push(this.Items[index]);
      this.Items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.$emit("qualityRemoved", this.deletedItems);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    refreshItems(product) {
      let vm = this;
      //vm.Items = [];
      product.operationSteps.forEach(function (step) {
        step.itemOperationQuality.forEach(function (item) {
          vm.Items.push({
            id: item.id,
            itemId: vm.id,
            operationId: item.operationId,
            serial: vm.nextSerial,
            operationName: step.name,
            name: item.name,
            minValue: item.minValue,
            maxValue: item.maxValue,
          });

          vm.nextSerial += 1;

          vm.$nextTick(() => {
            vm.trAddHeight(100);
          });
        });
      });
    },
    formChanged() {
      let vm = this;
      vm.Product.operationSteps.forEach(function (item, index) {
        console.log(item);
        let operationQuality = vm.Items.filter((obj) => {
          return obj.operationName === item.name;
        });
        if (operationQuality.length > 0) {
          item.operationQuality = [];
          operationQuality.forEach(function (qualityItem) {
            item.operationQuality.push({
              id: qualityItem.id
                ? qualityItem.id
                : "00000000-0000-0000-0000-000000000000",
              operationId: qualityItem.operationId
                ? qualityItem.operationId
                : "00000000-0000-0000-0000-000000000000",
              name: qualityItem.name,
              itemId: vm.id,
              minValue: qualityItem.minValue,
              maxValue: qualityItem.maxValue,
            });
          });
        }
      });
      vm.$emit("onchange", vm.Product);
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
