<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="!loadingSpinner && !errorNotFoundCheck">
      <b-card class="my-2">
        <b-row>
          <b-col>
            <h2 class="text-primary m-0 p-0">Product manufactured inhouse?</h2>
          </b-col>
          <b-col cols="12" class="d-flex align-items-center justify-content-start my-1">
            <label for="check-button" class="mr-2 text-primary">No</label>
            <b-form-checkbox
              checked="false"
              name="check-button"
              switch
              inline
              v-model="product.inHouseManufactured"
              disabled
            >
            </b-form-checkbox>
            <label for="check-button" class="text-primary">Yes</label>
          </b-col>
          <b-col>
            <h3></h3>
          </b-col>
          <div class="d-flex justify-content-center">
            <b-spinner large v-if="loading" class="mx-2" />
            <span v-if="loading">Loading...</span>
          </div>
        </b-row>
      </b-card>
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        ref="formWiz"
        v-if="product.inHouseManufactured == true"
      >
        <!-- Search details tab -->
        <tab-content title="Details">
          <EditProductBuy v-if="product" :Product="product" @onchange="detailsChanged" />
        </tab-content>
        <tab-content title="Bill Of Materials">
          <EditBOM
            :Product="product"
            @onchange="bomChanged"
            @item-removed="handleItemBomRemoved"
          />
        </tab-content>
        <tab-content title="Operation Steps">
          <EditBuildProcess
            :Product="product"
            @onchange="operationChanged"
            @subRemoved="handleSubRemoved"
            @mainRemoved="handleMainRemoved"
            @equipmentRemoved="handleEquipmentRemoved"
          />
        </tab-content>
        <tab-content title="Pricing">
          <EditConfirmProduct :Product="product" @onchange="pricingChanged" />
        </tab-content>
        <tab-content title="Quality Control">
          <EditOperationQuality
            :Product="product"
            :Items="qualityItems"
            @onchange="qualityChanged"
            @qualityRemoved="handleQualityRemoved"
          />
        </tab-content>
        <tab-content title="Finish">
          <b-container class="d-flex justify-content-center last_step flex-column">
            <img src="./check-mark.svg" style="height: 200px" alt="" />
            <h2 class="text-center mt-3 text-primary">Submit and Confirm</h2>
          </b-container>
        </tab-content>
      </form-wizard>
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        ref="formWiz"
        v-if="
          product.inHouseManufactured === null || product.inHouseManufactured == false
        "
      >
        <!-- Search details tab -->
        <tab-content title="Details">
          <EditProduct :Product="product" @onchange="detailsChanged" />
        </tab-content>
        <tab-content title="Pricing">
          <EditConfirmProduct :Product="product" @onchange="pricingChanged" />
        </tab-content>
        <tab-content title="Finish">
          <b-container class="d-flex justify-content-center last_step flex-column">
            <img src="./check-mark.svg" style="height: 200px" alt="" />
            <h2 class="text-center mt-3 text-primary">Submit and Confirm</h2>
          </b-container>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import EditConfirmProduct from "./EditConfirmProduct.vue";
import EditProduct from "./EditProduct.vue";
import EditBuildProcess from "./EditBuildProcess.vue";
import EditProductBuy from "./EditProductBuy.vue";
import EditOperationQuality from "./EditOperationQuality.vue";
import EditBOM from "./EditBOM.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BContainer,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import { codeIcon } from "../AddProduct/LeadsWizard.js";
import api from "@/api/api-v2-config";

export default {
  components: {
    FormWizard,
    BContainer,
    EditBuildProcess,
    EditBOM,
    BButton,
    BFormCheckbox,
    BCard,
    TabContent,
    BRow,
    EditOperationQuality,
    EditProduct,
    BCol,
    EditProductBuy,
    EditConfirmProduct,
    ToastificationContent,
    BSpinner,
  },
  data() {
    return {
      id: this.$route.params.id,
      codeIcon,
      productFile: null,
      totalBomCost: 0,
      loadingSpinner: true,
      totalOperationCost: 0,
      qualityItems: [],
      billOfMaterials: [],
      bomRemoved: [],
      subRemoved: [],
      mainRemoved: [],
      equipmentRemoved: [],
      qualityRemoved: [],
      errorNotFoundCheck: false,
    };
  },
  watch: {
    totalBomCost: {
      handler: function () {
        this.refreshSalePrice();
      },
    },
    totalOperationCost: {
      handler: function () {
        this.refreshSalePrice();
      },
    },
  },
  methods: {
    ...mapActions("productModule", ["getProductIDAction", "updateProductAction"]),
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),

    handleItemBomRemoved(removedItem) {
      this.bomRemoved = removedItem;
    },

    handleSubRemoved(removedItem) {
      console.log("Sub removed", removedItem);
      this.subRemoved = removedItem;
    },
    handleMainRemoved(removedItem) {
      console.log("Main removed", removedItem);
      this.mainRemoved = removedItem;
    },
    handleEquipmentRemoved(removedItem) {
      this.equipmentRemoved = removedItem;
    },
    handleQualityRemoved(removedItem) {
      this.qualityRemoved = removedItem;
    },
    neckStep() {
      this.$refs.formWiz.nextTab();
    },
    detailsChanged(product) {
      this.productFile = product.uploadedFile;
    },

    bomChanged(boms) {
      let vm = this;
      vm.product.billOfMaterials = [];
      vm.totalBomCost = 0;
      boms.forEach(function (item, index) {
        vm.product.billOfMaterials.push({
          id: item.bomId || "00000000-0000-0000-0000-000000000000",
          itemId: item.itemId || vm.id,
          materialId: item.id,
          itemUomId: item.uom,
          quantity: item.qty,
          totalCost: item.totalCost,
          itemBOMOrder: index,
        });
        vm.totalBomCost += parseFloat(item.totalCost);
      });
      vm.product.purchasePrice =
        parseFloat(vm.totalBomCost) + parseFloat(vm.totalOperationCost);
    },

    operationChanged(operations) {
      let vm = this;
      let oldSteps = vm.product.operationSteps;
      vm.product.operationSteps = [];
      vm.totalOperationCost = 0;
      operations.forEach(function (item, index) {
        vm.product.operationSteps.push({
          id: item.id || "00000000-0000-0000-0000-000000000000",
          name: item.name,
          itemId: vm.id,
          workstation: item.workstation,
          itemOperationOrder: index,
          cycles: item.cycles,
          cycleHours: item.cycleHours,
          labourId: item.labourId,
          labourCost: item.labourCost || 0,
          equipmentId: item.equipmentId,
          directCostName: "N/A",
          equipmentCost: item.equipmentCost || 0,
          inDirectCostName: item.inDirectCostName || "N/A",
          indirectCost: item.indirectCost || 0,
          directCost: item.directCost || 0,
          duration: item.duration,
          totalCost: item.totalCost || 0,
          itemOperationQuality: vm.getOperationQuality(oldSteps, item.name),
          subOperations: item.substeps.map((substep, index) => {
            return {
              id: substep.id || "00000000-0000-0000-0000-000000000000",
              itemId: vm.id,
              itemOperationId: item.id || "00000000-0000-0000-0000-000000000000",
              labourId: substep.labourId,
              name: substep.name,
              workstation: substep.workstation,
              labourCost: substep.labourCost,
              indirectCost: substep.indirectCost,
              labourQuantity: substep.labourQty,
              cycles: substep.cycles,
              cycleHours: substep.cycleHours,
              directCost: substep.directCost,
              totalLabourCost: substep.totalLabourCost,
              totalEquipmentCost: substep.totalEquipmentCost,
              directCostName: "N/A",
              totalCost: substep.totalCost,
              InDirectCostName: "N/A",
              itemOperationOrder: index,
              isDeleted: false,
              itemSubOperationEquipment: substep.equipments.map((equipment, index) => {
                return {
                  id: equipment.id || "00000000-0000-0000-0000-000000000000",
                  equipmentId: equipment.equipmentId,
                  equipmentCost: equipment.equipmentCost,
                  equipmentQuantity: equipment.equipmentQty,
                  totalEquipmentCost: equipment.totalEquipmentCost,
                  itemOperationEquipmentOrder: index,
                  itemSubOperationId:
                    equipment.itemSubOperationId ||
                    "00000000-0000-0000-0000-000000000000",
                  isDeleted: false,
                };
              }),
            };
          }),
        });
        vm.totalOperationCost += item.totalCost;
      });
      vm.product.purchasePrice = vm.totalBomCost + vm.totalOperationCost;
      vm.pricingChanged(vm.product);
      vm.refreshQualityItems(vm.product);
    },
    qualityChanged(product) {
      console.log("Product emitted from quality component", product);
      let vm = this;
      vm.product = JSON.parse(JSON.stringify(product));
      console.log("Product after being assigned", vm.product);
    },
    getOperationQuality(oldSteps, currentName) {
      let step = oldSteps.filter((obj) => {
        return obj.name === currentName;
      });
      if (step.length > 0) {
        return step[0].itemOperationQuality;
      }
      return [];
    },
    refreshQualityItems(product) {
      let vm = this;
      vm.qualityItems = [];
      let serial = 1;
      if (product.operationSteps !== null) {
        product.operationSteps.forEach(function (step) {
          step.itemOperationQuality.forEach(function (item) {
            vm.qualityItems.push({
              id: item.id,
              operationId: step.id,
              serial: serial,
              operationName: step.name,
              name: item.name,
              minValue: item.minValue,
              maxValue: item.maxValue,
            });
            serial++;
          });
        });
      }
    },
    pricingChanged(product) {
      let markupAmount =
        parseFloat(product.purchasePrice || 0) *
        (parseFloat(product.markUpPercentage || 0) / 100);
      let priceBeforeTax = parseFloat(product.purchasePrice || 0) + markupAmount;
      let taxAmount = priceBeforeTax * (parseFloat(product.saleTaxPercentage || 0) / 100);
      product.salePrice = (priceBeforeTax + taxAmount).toFixed(2);
      this.product = product;
    },
    refreshSalePrice() {
      let markupAmount =
        parseFloat(this.product.purchasePrice || 0) *
        (parseFloat(this.product.markUpPercentage || 0) / 100);
      let priceBeforeTax = parseFloat(this.product.purchasePrice || 0) + markupAmount;
      let taxAmount =
        priceBeforeTax * (parseFloat(this.product.saleTaxPercentage || 0) / 100);
      this.product.salePrice = (priceBeforeTax + taxAmount).toFixed(2);
    },

    refreshItems(product) {
      let vm = this;
      vm.items = [];
      let serial = 1;
      product.billOfMaterials.forEach(function (item) {
        let newItem = {
          serial: serial,
          id: item.materialId,
          uom: item.itemUomId,
          qty: item.quantity,
        };
        vm.calcBomCost(newItem);
        vm.calTotalBomAndOperationCost(product);
        vm.billOfMaterials.push(newItem);
        serial += 1;
      });
    },

    calcBomCost(item) {
      let selectedMaterial = this.materials.filter((obj) => {
        return obj.id === item.id;
      });
      if (selectedMaterial.length > 0) {
        item.cost =
          (selectedMaterial[0].purchasePrice || 0) == 0
            ? selectedMaterial[0].salePrice || 0
            : selectedMaterial[0].purchasePrice || 0;
        item.totalCost = (item.qty || 0) * (item.cost || 0);
        this.totalBomCost += item.totalCost;
      }
    },
    calTotalBomAndOperationCost(product) {
      product.operationSteps.forEach((item) => {
        this.totalOperationCost += item.totalCost;
      });
      product.purchasePrice =
        parseFloat(this.totalBomCost) + parseFloat(this.totalOperationCost);
    },

    deleteFunctions() {
      let payload = {
        id: this.id,
        itemOperations: [],
        itemSubOperations: [],
        itemSubOperationsEquipment: [],
        itemBom: [],
        itemOperationQuality: [],
      };

      this.bomRemoved.forEach((item) => {
        if (item.bomId !== null && item.bomId !== undefined) {
          payload.itemBom.push({
            bomId: item.bomId,
          });
        }
      });

      this.subRemoved.forEach((subItem) => {
        if (subItem.id !== null && subItem.id !== undefined) {
          const isMainRemovedItemMatching = this.mainRemoved.some(
            (mainItem) => mainItem.id === subItem.itemOperationId
          );
          if (!isMainRemovedItemMatching) {
            payload.itemSubOperations.push({
              subOperationId: subItem.id,
            });
          }
        }
      });

      this.mainRemoved.forEach((item) => {
        if (item.id !== null && item.id !== undefined) {
          payload.itemOperations.push({
            operationId: item.id,
          });
        }
      });

      this.equipmentRemoved.forEach((item) => {
        if (item.id !== null && item.id !== undefined) {
          payload.itemSubOperationsEquipment.push({
            subOperationEqId: item.id,
          });
        }
      });
      this.qualityRemoved.forEach((item) => {
        if (item.id !== null && item.id !== undefined) {
          payload.itemOperationQuality.push({
            qltyIds: item.id,
          });
        }
      });

      api
        .delete("products/deleteoperation", { data: payload })
        .then((response) => {})
        .catch((error) => {});
    },
    formSubmitted() {
      console.log("Product before being sent", this.product);
      this.product.operationSteps.forEach((item) => {
        item.itemOperationQuality = item.operationQuality;
        if (
          item.id !== "00000000-0000-0000-0000-000000000000" &&
          item.itemOperationQuality &&
          item.itemOperationQuality.length > 0
        ) {
          item.itemOperationQuality.forEach((quality) => {
            quality.operationId = item.id;
          });
        }
      });

      let allArraysEmpty =
        this.bomRemoved.length === 0 &&
        this.subRemoved.length === 0 &&
        this.mainRemoved.length === 0 &&
        this.equipmentRemoved.length === 0 &&
        this.qualityRemoved.length === 0;

      if (!allArraysEmpty) {
        this.deleteFunctions();
      }

      this.product.operationSteps.forEach((item) => {
        delete item.operationQuality;
      });
      this.updateProductAction({ product: this.product, file: this.productFile })
        .then(() => {
          this.success();
          this.$bvModal.hide("modal-1");
        })
        .catch(() => {
          this.error();
          this.$bvModal.hide("modal-1");
        });
    },
    success() {
      this.$swal({
        title: "Products!",
        text: "You have successfully edited the product!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: "product-list" });
        }
      });
    },
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    errorNotFound() {
      this.$swal({
        title: "Error!",
        text: " Product not found!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    redirectToListing() {
      this.$router.push({ name: "product-list" });
    },
  },
  async mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);
    await this.getRawMaterialListAction();
    await this.getProductIDAction(this.id)
      .then(() => {
        this.refreshItems(this.product);
      })
      .catch(() => {
        this.errorNotFound();
        this.errorNotFoundCheck = true;
      });
    this.product.id = this.id;
  },
  computed: {
    ...mapGetters("productModule", {
      product: "productID",
      loading: "loading",
    }),
    ...mapGetters("rawmaterialModule", {
      materials: "rawmaterials",
      loading: "loading",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style scoped>
label {
  font-size: 1.714rem !important;
  font-weight: 500;
  margin-bottom: 0;
}
</style>
