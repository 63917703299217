<template>
  <b-form @submit.prevent="formSubmitted" @change="formChanged()">
    <b-row>
      <b-col md="6">
        <b-form-group label="Product Name" label-for="mc-Product-name">
          <b-form-input
            id="mc-Product-name"
            placeholder="Product Name"
            v-model="Product.name"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Product classification" label-for="mc-Product-name">
          <b-form-select v-model="Product.type" :options="productTypes" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Product Code" label-for="mc-Product-Code">
          <b-form-input
            id="mc-Product-Code"
            placeholder="Product Code"
            v-model="Product.code"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Barcode" label-for="mc-Barcode">
          <b-form-input
            id="mc-Barcode"
            type="text"
            placeholder="Barcode"
            v-model="Product.barcode"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Unit Of Measure">
          <div class="form-label-group">
            <b-form-input
              id="mc-Product-Code"
              placeholder="Product Code"
              v-model="Product.uomName"
              readonly
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
          <div class="form-label-group">
            <b-form-input
              id="mc-stock"
              type="text"
              placeholder="Low Stock Threshold"
              v-model="Product.lowStock"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="batchqty" label="Batch Quantity">
          <b-form-input
            id="mc-Product-batchqty"
            placeholder="Batch Quantity"
            v-model="Product.productionLimit"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Category" label-for="mc-Category">
          <b-form-select
            v-model="Product.categoryID"
            :options="itemCategories"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Preferred Supplier" label-for="mc-Supplier">
          <b-form-select
            v-model="Product.supplierId"
            :options="suppliers"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mb-2">
        <label for="textarea-default">Product Description</label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Product Description"
          rows="5"
          v-model="Product.description"
        />
      </b-col>

      <b-col cols="12">
        <b-form-group label="Upload / Edit Image">
          <b-form-file
            id="file-input"
            ref="fileInput"
            @change="onFileChange"
            placeholder="Upload an image..."
            class="col-10"
          ></b-form-file>
          <b-button
            @click="clearImage"
            variant="danger"
            v-if="uploadedImage"
            class="mt-1 mx-2"
            >Clear Image</b-button
          >
        </b-form-group>

        <b-card-text title="Product Image" v-if="Product.productImage || uploadedImage">
          <img v-if="uploadedImage" :src="uploadedImage" width="250" height="250" />
          <img v-else :src="'data:image/jpeg;base64,' + Product.productImage" />
        </b-card-text>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BFormSelect,
    BCol,
    ToastificationContent,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    Product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedImage = e.target.result;
        this.uploadedFile = file;
        this.formChanged();
      };
      reader.readAsDataURL(file);
    },
    clearImage() {
      this.uploadedImage = null;
      this.uploadedFile = null;
      this.$refs.fileInput.value = "";
    },
    formSubmitted() {
      this.$router.push("/product/list/");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Product Successfully Added",
          icon: "EditIcon",
          variant: "success",
        },
      }),
        this.$bvModal.hide("modal-1");
    },
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("itemcategoryModule", ["getItemCategoryListAction"]),
    ...mapActions("supplierModule", ["getSupplierListAction"]),
    formChanged() {
      this.$emit("onchange", { product: this.Product, uploadedFile: this.uploadedFile });
    },
    checkUomId() {
      if (!this.Product.uomid) {
        let uom = this.itemUoms.filter((obj) => {
          return obj.name === this.Product.uomName;
        });
        if (uom.length > 0) {
          this.Product.uomid = uom[0].id;
        }
      }
    },
  },
  data() {
    return {
      uploadedImage: null, // Add this to hold the new image data
      uploadedFile: null,
      productTypes: [
        { value: 1, text: "Finished" },
        { value: 2, text: "Semi Finished" },
      ],
    };
  },
  computed: {
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      loading: "loading",
    }),
    ...mapGetters("itemcategoryModule", {
      itemCategories: "itemcategories",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
      loading: "loading",
    }),
  },
  async mounted() {
    await this.getItemUOMListAction().then(() => {
      this.checkUomId();
    });
    await this.getItemCategoryListAction();
    await this.getSupplierListAction();
  },
};
</script>
